import React from "react";
import PropTypes from "prop-types";
import TimePicker from "rc-time-picker";
import { useTranslation } from "react-i18next";
import { i18n } from "../../translations/i18n";
const moment = require("moment");
export const TimePrompt = ({ updateResponse, hasError }) => {
  const { t } = useTranslation();
  const str = "HH:mm";
  const handleChange = value => {
    updateResponse(value === null ? "" : value.format(str));
  };
  return (
    <TimePicker
      placeholder={t('select_time')}
      use12Hours
      showSecond={false}
      onChange={handleChange}
      minuteStep={15}
      className={"w-full"}
      inputClassName={"bp4-input "+ (hasError ? "bp4-intent-danger" : "")}
      defaultOpenValue={moment().startOf("day")}
    />
  );
};

TimePrompt.propTypes = {
  updateResponse: PropTypes.func,
  hasError: PropTypes.bool,
};
