import React, { useState } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@blueprintjs/core";
import { HORIZONTAL } from "../../helpers/variants";
import { includes } from "lodash";
import cx from "classnames";

export const SelectManyPrompt = ({
  promptOptions,
  updateResponse,
  displayVariant
}) => {
  const [selectedOptions, updateSelectedOptions] = useState([]);
  const handleOnChange = value => {
    const updatedOptions = selectedOptions.find(
      option => option.prompt_option_id === value.prompt_option_id
    )
      ? selectedOptions.filter(
          option => option.prompt_option_id !== value.prompt_option_id
        )
      : [...selectedOptions, value];
    updateSelectedOptions(updatedOptions);
    updateResponse({
      value: updatedOptions.map(option => option.value).join("|"),
      prompt_option_ids: updatedOptions.map(option => option.prompt_option_id)
    });
  };

  return (
    <React.Fragment>
      {promptOptions.map(option => (
        <Checkbox
          key={option.id}
          onChange={() => {
            return handleOnChange({
              value: option.value,
              prompt_option_id: option.id
            });
          }}
          className={cx("selectOption", {
            "selectOption--selected": selectedOptions.some(
              selected => selected.value === option.value
            ),
            "selectOption--horizontal": displayVariant === HORIZONTAL
          })}
          inline={displayVariant === HORIZONTAL}
        >
          <span className="selectOption--displayText">
            {option.display_text ? option.display_text : option.value}
          </span>
        </Checkbox>
      ))}
    </React.Fragment>
  );
};

SelectManyPrompt.propTypes = {
  value: PropTypes.string,
  promptOptions: PropTypes.array,
  updateResponse: PropTypes.func,
  displayVariant: PropTypes.string
};