import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PromptResponseContainer as PromptResponse } from "./PromptResponseComponent";
import cx from "classnames";
//import marky from "@npmcorp/marky-markdown";
import { Icon, Intent } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import { i18n } from "../../translations/i18n";

export const Panel = ({
  panel,
  prompts,
  promptOptions,
  surveyConditions,
  fulfilledConditions,
  hidden,
  preview,
  guidanceText,
  errorPrompts,
  shortName,
  noCollection,
}) => {
  const { t } = useTranslation();
  const [groupedPropmts, setGroupedPropmts] = useState([]);
  useEffect(() => {
    var group = [];
    var gPropmts = [];
    prompts.forEach((prompt, idx) => {
      if (prompt.is_important) {
        group.push(prompt);
        if (
          !prompts[idx + 1] ||
          (prompts[idx + 1] && !prompts[idx + 1].is_important)
        ) {
          gPropmts.push(group);
          group = [];
        }
      } else {
        gPropmts.push(prompt);
      }
    });
    setGroupedPropmts(gPropmts);
  }, []);
  return (
    <div className={cx("min-h-250 flex-wrap", { hidden: hidden })}>
      {(panel.title || panel.description) && (
        <div className="mb-2 bp4-callout bp4-callout-icon bp4-intent-primary">
          <Icon icon="issue" iconSize="20" />
          {panel.title && <h4 className="bp4-heading">{panel.title}</h4>}
          {panel.description && (
            <div
              dangerouslySetInnerHTML={{
                __html: panel.description,
              }}
            />
          )}
        </div>
      )}
      {prompts && prompts.map((prompt) => prompt.is_required).includes(true) && (
        <div className="mb-6">
          <span
            id="guidance"
            className={guidanceText ? "text-red" : "text-grey"}
          >
            <Icon
              icon="flag"
              intent={guidanceText ? Intent.DANGER : Intent.NONE}
            />{" "}
            - {t("guidance_text")}
          </span>
        </div>
      )}

      {groupedPropmts &&
        groupedPropmts.map((groupedPropmt) => (
          <div
            className="w-full"
            key={
              Array.isArray(groupedPropmt)
                ? groupedPropmt[0].id
                : groupedPropmt.id
            }
          >
            {!Array.isArray(groupedPropmt) && (
              <PromptResponse
                key={groupedPropmt.id}
                prompt={groupedPropmt}
                promptOptions={promptOptions.filter(
                  (option) => option.prompt_id === groupedPropmt.id
                )}
                surveyConditions={surveyConditions}
                fulfilledConditions={fulfilledConditions}
                preview={preview}
                hasError={(errorPrompts.indexOf(groupedPropmt.id) >= 0)}
                shortName={shortName}
                noCollection={noCollection}
              />
            )}

            {Array.isArray(groupedPropmt) && (
              <div className="border border-gray-700 p-3">
                {groupedPropmt.map((prompt) => (
                  <PromptResponse
                    key={prompt.id}
                    prompt={prompt}
                    promptOptions={promptOptions.filter(
                      (option) => option.prompt_id === prompt.id
                    )}
                    surveyConditions={surveyConditions}
                    fulfilledConditions={fulfilledConditions}
                    preview={preview}
                    hasError={(errorPrompts.indexOf(prompt.id) >= 0)}
                    shortName={shortName}
                    noCollection={noCollection}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

Panel.propTypes = {
  panel: PropTypes.object,
  prompts: PropTypes.array,
  promptOptions: PropTypes.array,
  hidden: PropTypes.bool,
  preview: PropTypes.bool,
  guidanceText: PropTypes.bool,
  surveyConditions: PropTypes.array,
  fulfilledConditions: PropTypes.array,
  errorPrompts: PropTypes.array,
  shortName: PropTypes.string,
  noCollection: PropTypes.bool,
};
