import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextArea, Intent  } from "@blueprintjs/core";
import { useDebounce } from "../../helpers/hooks/useDebounce";

export const LongTextPrompt = ({ updateResponse, hasError }) => {
  return (
    <TextArea
        className="w-full"
        style={{ height: '6em'}}   
        large={true}
        intent={hasError ? Intent.DANGER : Intent.NONE}
        onBlur={(event) => { updateResponse(event.target.value); }}
    />
  );
};

LongTextPrompt.propTypes = {
  updateResponse: PropTypes.func,
  hasError: PropTypes.bool,
};
