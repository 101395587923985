export const ADD_PROMPT_RESPONSES = "ADD_PROMPT_RESPONSES";
export const SET_PROMPT_RESPONSES = "SET_PROMPT_RESPONSES";
export const SET_SURVEY_RESPONSE = "SET_SURVEY_RESPONSE";
export const SET_SURVEY_SHORT_NAME = "SET_SURVEY_SHORT_NAME";
export const SET_SURVEY_CONDITIONS = "SET_SURVEY_CONDITIONS";
export const SET_TERMINAL_CONDITIONS = "SET_TERMINAL_CONDITIONS";
export const UPDATE_SURVEY_CONDITIONS_FROM_RESPONSES =
  "UPDATE_SURVEY_CONDITIONS_FROM_RESPONSES";
export const UPDATE_SURVEY_RESPONSE = "UPDATE_SURVEY_RESPONSE";
export const UPDATE_PROMPT_RESPONSES = "UPDATE_PROMPT_RESPONSES";
