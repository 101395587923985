import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  InputGroup,
  HTMLSelect,
  FormGroup,
  Icon,
  Intent,
} from "@blueprintjs/core";
import { useDebounce } from "../../helpers/hooks/useDebounce";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { useTranslation } from "react-i18next";
import { i18n } from "../../translations/i18n";

const Helper = () => <span>Please provide a valid phone number</span>;

export const PhoneNumberPrompt = ({ updateResponse, hasError }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const Helper = ({ visible }) => (
    <span className={visible ? "" : "invisible"}>
      {t("please_provide_valid_phone_number")}
    </span>
  );
  return (
    <div>
      <div className="flex">
        <div className="w-11/12">
          <PhoneInput
            defaultCountry="US"
            value={value}
            onChange={(value) => setValue(value)}
            className={"bp4-input "+ (hasError ? "bp4-intent-danger" : "")}
            onBlur={(event) => {
              updateResponse(value);
            }}
          />
        </div>
      
        <Icon
          icon={value ? isValidPhoneNumber(value) ? "tick" : "cross" : ""}
          intent={value ? isValidPhoneNumber(value) ? Intent.SUCCESS : Intent.DANGER : ""}
          className={`w-5 ${value ? "visible" : "invisible"}`}
        />
      </div>
      {value && !isValidPhoneNumber(value) && (<div className="bp4-form-helper-text"><Helper visible={value ? !isValidPhoneNumber(value) : ""} /></div>)}
        
    </div>
  );
};

PhoneNumberPrompt.propTypes = {
  updateResponse: PropTypes.func,
  hasError: PropTypes.bool,
};
