import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Switch, Checkbox, Icon, Intent } from "@blueprintjs/core";
import { useDebounce } from "../../helpers/hooks/useDebounce";


import { useTranslation } from "react-i18next";
import { i18n } from "../../translations/i18n";

export const BooleanPrompt = ({ updateResponse, hasError }) => {
	const { t } = useTranslation();
	const [inputValue, setInputValue] = useState("");
	const debouncedInputValue = useDebounce(inputValue);
	useEffect(() => {
		updateResponse(debouncedInputValue);
	}, [debouncedInputValue]);
	useEffect(() => {
		setInputValue("false");
	}, []);
	return (
		<span className={(hasError ? "pr-2" : "")} >
			<Checkbox 
				className={"inline"}
				onChange={event =>
					updateResponse(event.target.checked ? "true" : "false")
				} 
			/>
			{ hasError && (<Icon
	      icon="flag"
	      intent={Intent.DANGER}
	    />)}
	  </span>
	);
};

BooleanPrompt.propTypes = {
	updateResponse: PropTypes.func,
	hasError: PropTypes.bool,
};
