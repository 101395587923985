import { last, isEmpty } from "lodash";

export const newResponseInSurveyResponses = (surveyResponse, surveyId) => ({
  survey_id: surveyId
});

export const promptResponse = ({ value, promptId }) => ({
  prompt_id: promptId,
  value
});
