import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputGroup, Intent } from "@blueprintjs/core";
import { useDebounce } from "../../helpers/hooks/useDebounce";

export const ShortTextPrompt = ({ updateResponse, hasError }) => {
  return (
    <InputGroup
      maxLength={255}
      className="bp4-fill"
      intent={hasError ? Intent.DANGER : Intent.NONE}
      onBlur={(event) => { updateResponse(event.target.value); }}
    />
  );
};

ShortTextPrompt.propTypes = {
  updateResponse: PropTypes.func,
  hasError: PropTypes.bool,
};
