import * as Types from "./actionTypes";
import { handleErrors } from "../../helpers/handle_errors";

const post = (endpoint, object) =>
  fetch(endpoint, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(object)
  });

const put = (endpoint, object) =>
  fetch(endpoint, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(object)
  });

export const setPromptResponses = promptResponses => ({
  promptResponses,
  type: Types.SET_PROMPT_RESPONSES
});

export const addPromptResponse = promptResponse => ({
  promptResponse,
  type: Types.ADD_PROMPT_RESPONSES
});

export const setSurveyResponse = surveyResponse => ({
  surveyResponse,
  type: Types.SET_SURVEY_RESPONSE
});

export const setSurveyConditions = surveyConditions => ({
  surveyConditions,
  type: Types.SET_SURVEY_CONDITIONS
});

export const setTerminalConditions = terminalConditions => ({
  terminalConditions,
  type: Types.SET_TERMINAL_CONDITIONS
});

export const updateSurveyResponse = surveyResponse => ({
  surveyResponse,
  type: Types.UPDATE_SURVEY_RESPONSE
});

export const updatePromptResponse = promptResponse => dispatch =>
  Promise.resolve(
    dispatch({
      promptResponse,
      type: Types.UPDATE_PROMPT_RESPONSES
    })
  ).then(
    dispatch((dispatch, getState) => {
      const { promptResponses } = getState();
      dispatch({
        promptResponses,
        type: Types.UPDATE_SURVEY_CONDITIONS_FROM_RESPONSES
      });
    })
  );

export const postSurveyResponse = surveyResponse => dispatch =>
  post("/s/"+surveyResponse.shortName+"/survey_response", surveyResponse)
    .then(response => response.json())
    .then(response => {
      localStorage.setItem("surveyResponse", JSON.stringify(response));
      return dispatch(setSurveyResponse(response));
    });

export const putSurveyResponse = (shortName, surveyResponse) => dispatch =>
  put(`/s/${shortName}/survey_response/${surveyResponse.id}`, {
    survey_response: surveyResponse
  })
    .then(response => response.json())
    .then(response => {
      if (response.submitted) {
        localStorage.removeItem("shortName");
      }
      return dispatch(setSurveyResponse(response));
    });

export const postPromptResponse = (shortName, promptResponse) => dispatch =>
  post(`/s/${shortName}/prompt_response`, promptResponse)
    .then(handleErrors)
    .then(response => response.json())
    .then(json => dispatch(updatePromptResponse(json)))
    .catch(error => console.log(error));
