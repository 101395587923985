import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDebounce } from "../../helpers/hooks/useDebounce";

export const UrlParamsPrompt = ({ value, updateResponse }) => {
  const [inputValue, setInputValue] = useState("");
  const debouncedInputValue = useDebounce(inputValue);
  const urlParams = new URLSearchParams(document.location.search);
  const paramValue = urlParams.get(value);
  useEffect(() => {
    updateResponse(debouncedInputValue);
  }, [debouncedInputValue]);
  useEffect(() => {
    setInputValue(paramValue === null ? "" : paramValue);
  }, []);
  return (null);
};

UrlParamsPrompt.propTypes = {
  updateResponse: PropTypes.func,
  value: PropTypes.string,
};
