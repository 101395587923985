import { useEffect, useState } from "react";
export const useFetch = (url, catchError = alert) => {
  const [loading, setLoading] = useState(true);
  const [fulfilled, setFulfilled] = useState(false);
  const [resource, setResource] = useState(null);
  const [error, setError] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);

  const handleErrors = response => {
    if (!response.ok) {
      throw {status: response.status};
    }
    return response;
  };

  useEffect(() => {
    if (!fulfilled) {
      fetch(url)
        .then(handleErrors)
        .then(response => response.json())
        .then(json => {
          setFulfilled(true);
          setResource(json);
          setLoading(false);
        })
        .catch(error => {
          setError(true);
          setErrorStatus(error.status)
          if (error.status === 401) {
            setUnauthorized(true);
          }
          setLoading(false);
          // catchError(error);
        });
    }
  }, [url]);

  return [loading, fulfilled, resource, error, unauthorized, errorStatus];
};
