// testing
import { includes, findIndex, isObject, isEmpty, uniq } from "lodash";
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Icon, Intent, ProgressBar, Button } from "@blueprintjs/core";
import { connect } from "react-redux";
import {
  setSurveyConditions,
  postSurveyResponse,
  putSurveyResponse,
  setSurveyShortName,
} from "../state/actions";
import { Panel } from "./PanelComponent";
import { PanelComponentContext } from "../../AdminApp/helpers/contexts";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { useTranslation } from "react-i18next";
import { i18n } from "../../translations/i18n";

const FIRST_PANEL_INDEX = 0;
const PANEL_INCREMENT_INDEX = 1;

const NoPanels = () => (
  <div className="bp4-non-ideal-state">
    <div className="bp4-non-ideal-state-visual">
      <Icon icon="document" iconSize="60" />
    </div>
    <h4 className="bp4-heading">No Panels created</h4>
  </div>
);

export const Survey = ({
  id,
  short_name,
  no_collection,
  panels,
  prompts,
  prompt_options,
  survey_conditions,
  fulfilledConditions,
  createSurveyResponse,
  setSurveyConditions,
  setSurveyShortName,
  handleSubmit,
  conditionTerminalPanelIndex,
  promptResponses,
}) => {
  const { t } = useTranslation();
  const panelComponentProps = useContext(PanelComponentContext);
  const preview = panelComponentProps ? panelComponentProps.preview : false;
  const finalPanelIndex =
    findIndex(panels, (panel) => panel.terminus) || panels.length;
  const [warningMessage, setWarningMessage] = useState(false);
  const [activePanelIndex, updateActivePanelIndex] = useState(
    FIRST_PANEL_INDEX
  );
  const [errorPropmpts, setErrorPropmpts] = useState([]);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }
  const warn = () => {
    window.scrollTo({
        top: 50,
        left: 100,
        behavior: "smooth",
    });
    setWarningMessage(true);
  };
  const validate = () => {
    if(preview || no_collection){
      return toggleNext()
    }
    var invalidProptResponse = []
    setErrorPropmpts([])
    const panel = panels[activePanelIndex];
    const requiredPanelPromptIds = prompts.reduce(
      (acc, prompt) =>
        prompt.owner_id === panel.id && prompt.is_required
          ? acc.concat(prompt.id)
          : acc,
      []
    );
    const emailPanelPromptIds = prompts.reduce(
      (acc, prompt) =>
        prompt.owner_id === panel.id && prompt.prompt_type === "email"
          ? acc.concat(prompt.id)
          : acc,
      []
    );

    const phonePanelPromptIds = prompts.reduce(
      (acc, prompt) =>
        prompt.owner_id === panel.id && prompt.prompt_type === "phone_number"
          ? acc.concat(prompt.id)
          : acc,
      []
    );

    if ((requiredPanelPromptIds || emailPanelPromptIds) && promptResponses) {
      const panelResponses = promptResponses.reduce(
        (acc, response) =>
          includes(requiredPanelPromptIds, response.prompt_id) &&
          ((prompts.find(prompt => prompt.id == response.prompt_id) || {}).prompt_type == 'boolean' ? response.value != 'false' : true) && 
          response.value !== ""
            ? acc.concat(response.prompt_id)
            : acc,
        []
      );

      requiredPanelPromptIds.map((requiredPanelPromptId) => {
        var response = promptResponses.find((promptResponse) => promptResponse.prompt_id === requiredPanelPromptId);
        if(!includes(promptResponses.map((promptResponse) => promptResponse.prompt_id), requiredPanelPromptId) ||
          ((prompts.find(prompt => prompt.id == requiredPanelPromptId) || {}).prompt_type == 'boolean' ? response.value == 'false' : false) ||
          !includes(panelResponses.map((panelResponse) =>  panelResponse), requiredPanelPromptId)
          ){
          invalidProptResponse.push(requiredPanelPromptId)
        }
      })


      const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let validEmails = true;
      emailPanelPromptIds.map((id) => {
        promptResponses.filter((promptResponse) => promptResponse.prompt_id === id).map((response) => {
          if (
            response.value &&
            !regEmail.test(response.value)
          ){
            validEmails = false;
            invalidProptResponse.push(response.prompt_id)
          }
        });
      });
      let validPhoneNumber = true;
      phonePanelPromptIds.map((id) => {
        promptResponses.filter((promptResponse) => promptResponse.prompt_id === id).map((response) => {
          if (
            response.value &&
            !isValidPhoneNumber(response.value)
          ){
            validPhoneNumber = false;
            invalidProptResponse.push(response.prompt_id)
          }
        });
      });
      setErrorPropmpts(uniq(invalidProptResponse))
      if (
        requiredPanelPromptIds.length === panelResponses.length &&
        validEmails &&
        validPhoneNumber
      ) {
        scrollToTop()
        setWarningMessage(false);
        return toggleNext()
      }
      return warn()
    }
  }

  const toggleNext = () => {
    if (conditionTerminalPanelIndex) {
      return updateActivePanelIndex(conditionTerminalPanelIndex);
    }
    return updateActivePanelIndex(activePanelIndex + PANEL_INCREMENT_INDEX);
  };

  const togglePrev = () =>
    activePanelIndex !== FIRST_PANEL_INDEX &&
    updateActivePanelIndex(activePanelIndex - PANEL_INCREMENT_INDEX);

  useEffect(() => {
    if (!preview && !no_collection) {
      createSurveyResponse(id, short_name);
      setSurveyConditions(survey_conditions);
    }
  }, []);

  useEffect(() => {
    if (
      !preview && !no_collection &&
      (activePanelIndex === conditionTerminalPanelIndex ||
      activePanelIndex === finalPanelIndex)
    ) {
      handleSubmit(short_name);
    }
  }, [activePanelIndex]);

  return (
    <div className="m-auto max-w-2xl">
      <div className="my-8">
        <ProgressBar
          value={activePanelIndex / finalPanelIndex}
          animate={false}
          intent={Intent.PRIMARY}
          stripes={false}
        />
      </div>
      {panels && activePanelIndex !== null ? (
        panels.map((panel, index) => (
          <Panel
            key={panel.id}
            panel={panel}
            prompts={prompts.filter((prompt) => prompt.owner_id === panel.id)}
            promptOptions={prompt_options}
            surveyConditions={survey_conditions}
            fulfilledConditions={fulfilledConditions}
            hidden={activePanelIndex !== index}
            guidanceText={warningMessage}
            preview={preview}
            errorPrompts={errorPropmpts}
            shortName={short_name}
            noCollection={no_collection}
          />
        ))
      ) : (
        <NoPanels />
      )}
      {activePanelIndex !== conditionTerminalPanelIndex && (
        <div className="flex justify-around my-8">
          <div className="text-left w-1/2">
            {activePanelIndex > 0 && activePanelIndex !== finalPanelIndex && (
              <Button
                onClick={() => {
                  togglePrev();
                  window.scrollTo(0, 0);
                }}
                large
                tabIndex={1}
              >
                {t("back")}
              </Button>
            )}
          </div>
          <div className="text-right w-1/2">
            {activePanelIndex >= 0 && activePanelIndex !== finalPanelIndex && (
              <div className="float-right">
                <Button
                    onClick={validate}
                    intent={Intent.PRIMARY}
                    large
                    tabIndex={0}
                  >
                    {t("next")}
                  </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Survey.propTypes = {
  id: PropTypes.string,
  survey_conditions: PropTypes.array,
  survey: PropTypes.object,
  panels: PropTypes.array,
  prompts: PropTypes.array,
  prompt_options: PropTypes.array,
  createSurveyResponse: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitted: PropTypes.bool,
  no_collection: PropTypes.bool,
  fulfilledConditions: PropTypes.array,
  setSurveyConditions: PropTypes.func,
  promptResponses: PropTypes.array,
  conditionTerminalPanelIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export const SurveyContainer = connect(
  (
    {
      surveyResponse,
      fulfilledConditions,
      selectedPromptOptions,
      promptResponses,
    },
    ownProps
  ) => ({
    ...ownProps,
    promptResponses,
    fulfilledConditions,
    submitted: surveyResponse ? surveyResponse.submitted : false,
    terminalConditions: ownProps.prompt_options
      .filter((promptOption) => promptOption.terminal_panel_id !== null)
      .map((promptOption) => ({
        promptOptionId: promptOption.id,
        panelId: promptOption.terminal_panel_id,
        fulfilled: includes(selectedPromptOptions, promptOption.id),
      })),
  }),
  (dispatch) => ({
    createSurveyResponse: (id, shortName) =>
      dispatch(postSurveyResponse({ survey_id: id, shortName: shortName })),
    setSurveyConditions: (surveyConditions) =>
      dispatch(setSurveyConditions(surveyConditions)),
    handleSubmit: (shortName) => {
      dispatch((dispatch, getState) => {
        const { surveyResponse } = getState();
        dispatch(
          putSurveyResponse(shortName, {
            ...surveyResponse,
            submitted: true,
          })
        );
      });
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    conditionTerminalPanelIndex: (() => {
      const fulfilledCondition = stateProps.terminalConditions.find(
        (condition) => condition.fulfilled
      );
      if (fulfilledCondition) {
        return findIndex(
          ownProps.panels,
          (panel) => panel.id === fulfilledCondition.panelId
        );
      }
      return false;
    })(),
  })
)(Survey);
