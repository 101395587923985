import React from "react";
import PropTypes from "prop-types";
import { DateInput } from "@blueprintjs/datetime";

import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/de";
import { i18n } from "../../translations/i18n";
import {  Intent  } from "@blueprintjs/core";

export const DatePrompt = ({ 
  updateResponse,
  minValue,
  maxValue,
  hasError,
}) => {
  const minDate =  minValue ? ( minValue.toLowerCase() == 'current' ? (new Date() )  : (new Date(minValue)) ) : (new Date(new Date().setFullYear(new Date().getFullYear() - 100)))
  const maxDate =  maxValue ? ( maxValue.toLowerCase() == 'current' ? (new Date() )  : (new Date(maxValue)) ) : (new Date(new Date().setFullYear(new Date().getFullYear() + 100)))
  const validateDate = (dateStr) => {
    if(dateStr){
      var selectedDate = new Date(dateStr)
      if((minDate && selectedDate < minDate) ||  (maxDate && selectedDate > maxDate)){
        updateResponse(null)
        return false
      } else {
        return new Date(dateStr)
      }

    }
  }
  return <DateInput
    locale={i18n.language}
    localeUtils={MomentLocaleUtils}
    formatDate={date => date.toLocaleDateString()}
    onChange={updateResponse}
    parseDate={str => validateDate(str)}
    placeholder={"MM/DD/YYYY"}
    minDate={minDate}
    maxDate={maxDate}
    inputProps={{className: (hasError ? "bp4-intent-danger" : "")}}
  />
};

DatePrompt.propTypes = {
  updateResponse: PropTypes.func,
  hasError: PropTypes.bool,
};
