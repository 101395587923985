export const API_ROLES = "/api/roles";
export const API_USERS = "/api/users";
export const API_USER_STUDIES = "/api/users/studies";
export const getApiUserPath = id => `${API_USERS}/${id}`;
export const API_CLINICAL_SITES = "/api/clinical_sites";
export const API_MULTI_MODEL_UPDATE = "/api/multi_model/update";
export const API_PROMPT_OPTIONS_PATH = "/api/prompt_options";
export const API_FAVORITES_PATH = "/api/favorites";
export const API_STUDIES_PATH = "/api/studies";
export const getApiSetStudyArchivePath = id => 
  `${API_STUDIES_PATH}/${id}/set_archive`;
export const getApiStudiesAssignUserPath = id =>
  `${API_STUDIES_PATH}/${id}/assign_user`;
export const getApiRemoveStudiesUserPath = id =>
  `${API_STUDIES_PATH}/${id}/remove_user`;
export const getApiStudiesMakeAdminPath = id =>
  `${API_STUDIES_PATH}/${id}/make_admin`;
export const getApiSurveysPath = id => `${API_STUDIES_PATH}/${id}/surveys`;
export const getApiSurveyPath = (studyId, id) =>
  `/api/studies/${studyId}/surveys/${id}`;
export const getApiDuplicateSurveysPath = (study_id, id) =>
  `${API_STUDIES_PATH}/${study_id}/surveys/${id}/duplicate`;
export const getApiSurveyConditionsPath = (studyId, id) =>
  `/api/studies/${studyId}/surveys/${id}/survey_conditions`;
export const getApiSurveyConditionPath = (studyId, surveyId, id) =>
  `/api/studies/${studyId}/surveys/${surveyId}/survey_conditions/${id}`;
export const getApiUserFavoritesPath = () => "/api/favorites";
export const getApiStudyPath = id => `${API_STUDIES_PATH}/${id}`;
export const getApiStudyContributorsPath = id =>
  `${API_STUDIES_PATH}/${id}/contributors`;
export const getApiClinicalSitePath = id => `${API_CLINICAL_SITES}/${id}`;
export const getApiPanelsPath = (studyId, SurveyId) =>
  `/api/studies/${studyId}/surveys/${SurveyId}/panels`;
export const getApiPanelPromptsPath = (studyId, surveyId, panelId) =>
  `/api/studies/${studyId}/surveys/${surveyId}/panels/${panelId}/prompts`;
export const getApiPanelLayoutGroupsPath = (studyId, surveyId, panelId) =>
  `/api/studies/${studyId}/surveys/${surveyId}/panels/${panelId}/layout_groups`;
export const getApiPanelPromptPromptOptionsPath = (
  studyId,
  surveyId,
  panelId,
  promptId
) =>
  `/api/studies/${studyId}/surveys/${surveyId}/panels/${panelId}/prompts/${promptId}/prompt_options`;
export const getApiLayoutGroupPromptsPath = (
  studyId,
  surveyId,
  layoutGroupId
) =>
  `/api/studies/${studyId}/surveys/${surveyId}/layout_groups/${layoutGroupId}/prompts`;
export const getApiLayoutGroupPromptPromptOptionsPath = (
  studyId,
  surveyId,
  layoutGroupId,
  promptId
) =>
  `/api/studies/${studyId}/surveys/${surveyId}/layout_groups/${layoutGroupId}/prompts/${promptId}/prompt_options`;
export const getApiMultiModelsUpdatePath = (studyId, surveyId) =>
  `/api/studies/${studyId}/surveys/${surveyId}/multi_model/update`;
export const getApiSetSurveyArchivePath = (study_id, id) =>
  `${API_STUDIES_PATH}/${study_id}/surveys/${id}/set_archive`;

export const ADMIN_PATH = "/admin";
export const ADMIN_STUDIES_PATH = "/admin/studies";
export const ADMIN_STUDY_PATH = "/admin/studies/:id";
export const ADMIN_NEW_STUDY_PATH = "/admin/new_study";
export const ADMIN_CLINICAL_SITES_PATH = "/admin/clinical_sites";
export const ADMIN_NEW_CLINICAL_SITE_PATH = "/admin/new_clinical_site";
export const ADMIN_EDIT_CLINICAL_SITE_PATH = "/admin/clinical_sites/:id/edit";
export const getAdminEditClinicalSitePath = id =>
  ADMIN_EDIT_CLINICAL_SITE_PATH.replace(":id", id);
export const ADMIN_NEW_SURVEY_PATH = "/admin/studies/:study_id/new_survey";
export const ADMIN_EDIT_SURVEY_PATH = "/admin/studies/:study_id/survey/:id/edit";
export const getAdminEditSurveyPath = (study_id, id) =>
  ADMIN_EDIT_SURVEY_PATH.replace(":study_id", study_id).replace(":id", id);
export const ADMIN_DUPLICATE_SURVEY_PATH =
  "/admin/studies/:study_id/surveys/:id/duplicate";
export const ADMIN_SURVEY_PATH = "/admin/studies/:study_id/surveys/:id";
export const ADMIN_STUDY_SITES_PATH = "/admin/studies/:id/sites";
export const ADMIN_USERS_PATH = "/admin/users";
export const ADMIN_NEW_USER_PATH = "/admin/new_user";
export const ADMIN_EDIT_USERS_PATH = "/admin/users/:id/edit";
export const getAdminEditUserPath = id =>
  ADMIN_EDIT_USERS_PATH.replace(":id", id);
export const ADMIN_EDIT_STUDY_PATH = "/admin/studies/:id/edit";
export const getAdminEditStudyPath = id => ADMIN_EDIT_STUDY_PATH.replace(":id", id);
export const getAdminStudyPath = id => `${ADMIN_STUDIES_PATH}/${id}`;
export const getAdminNewSurveyPath = id =>
  ADMIN_NEW_SURVEY_PATH.replace(":study_id", id);
export const getAdminDuplicateSurveyPath = (study_id, id) =>
  ADMIN_DUPLICATE_SURVEY_PATH.replace(":study_id", study_id).replace(":id", id);
export const getAdminSurveyPath = (id, studyId) =>
  ADMIN_SURVEY_PATH.replace(":id", id).replace(":study_id", studyId);
export const getWildcardSurveyPath = (short_name, accessCode) => `${(short_name != '' && short_name != null) ? "/s/"+short_name+"" : ""}/get_survey?access_code=${accessCode}`;
export const ADMIN_SURVEY_WEBHOOKS_PATH =
  "/admin/studies/:study_id/surveys/:id/webhooks";
export const getAdminSurveyWebhooksPath = (studyId, id) =>
  ADMIN_SURVEY_WEBHOOKS_PATH.replace(":id", id).replace(":study_id", studyId);
export const reprocessApiEntityWebhookPath = (entityType, entityId, webhook_id) =>
  `/api/${entityType}/${entityId}/webhooks/${webhook_id}/reprocess`;
export const getApiEntityWebhooksPath = (entityType, entityId) =>
  `/api/${entityType}/${entityId}/webhooks`;
export const ADMIN_SURVEY_RESPONSES_PATH =
  "/admin/studies/:study_id/surveys/:id/responses";
export const getAdminSurveyResponsesPath = (id, studyId) =>
  ADMIN_SURVEY_RESPONSES_PATH.replace(":study_id", studyId).replace(":id", id);
export const getApiSurveyResponsesPath = (id, studyId) =>
  `/api/studies/${studyId}/surveys/${id}/survey_responses`;
export const ADMIN_EDIT_PROFILE_PATH = "/admin/edit_profile";
export const getApiStudyUsersPath = (studyId) => `/api/studies/${studyId}/users`;
export const LOGOUT_PATH = "/users/sign_out";
