import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NumericInput, Intent } from "@blueprintjs/core";
import { useDebounce } from "../../helpers/hooks/useDebounce";


import { useTranslation } from "react-i18next";
import { i18n } from "../../translations/i18n";

export const NumberPrompt = ({
  value,
  promptResponse,
  updateResponse,
  minValue,
  maxValue,
  hasError,
}) => {
  const { t } = useTranslation();
  return (
    <NumericInput
      type="text"
      inputMode="numeric"
      buttonPosition="none"
      placeholder={t('enter_your_value')}
      min={Number(minValue)  || 0}
      max={Number(maxValue)}
      className="bp4-fill"
      intent={hasError ? Intent.DANGER : Intent.NONE}
      onBlur={(event) => { updateResponse(event.target.value); }}
    />
  );
};

NumberPrompt.propTypes = {
  value: PropTypes.string,
  promptResponse: PropTypes.object,
  promptOptions: PropTypes.array,
  updateResponse: PropTypes.func,
  hasError: PropTypes.bool,
};
