export const SINGLE_SELECT = "single_select";
export const MULTI_SELECT = "multi_select";
export const BOOLEAN = "boolean";
export const SHORT_TEXT = "short_text";
export const LONG_TEXT = "long_text";
export const PHONE_NUMBER = "phone_number";
export const EMAIL = "email";
export const NUMBER = "number";
export const DATE = "date";
export const TIME = "time";
export const CONTENT = "content";
export const URL_PARAMS = "url_params";
