import React, { useState } from "react";
import PropTypes from "prop-types";
import { HORIZONTAL } from "../../helpers/variants";
import { RadioGroup, Radio } from "@blueprintjs/core";
import cx from "classnames";
export const SingleSelectPrompt = ({
  promptOptions,
  updateResponse,
  promptResponse,
  displayVariant,
  noCollection,
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleOnChange = (selectedOption) => {
    const userInput = selectedOption.value;
    setInputValue(userInput);
    updateResponse({
      value: selectedOption.value,
      prompt_option_ids: selectedOption.id,
    });
  };
  return (
    <React.Fragment>
      {promptOptions.map((option) => (
        <RadioGroup
          onChange={() => {
            return handleOnChange({
              value: option.value,
              prompt_option_id: option.id,
            });
          }}
          key={option.id}
          selectedValue={noCollection ? inputValue : promptResponse.value}
          inline={displayVariant === HORIZONTAL}
          className={cx({
            "radioGroup--horizontal": displayVariant === HORIZONTAL,
          })}
        >
          <Radio
            key={option.id}
            label={option.display_text ? option.display_text : option.value}
            value={option.value || ""}
            data-id={option.id}
            className={cx("selectOption", {
              "selectOption--selected": noCollection
                ? option.value === inputValue
                : option.value === promptResponse.value,
              "selectOption--horizontal": displayVariant === HORIZONTAL,
            })}
          />
        </RadioGroup>
      ))}
    </React.Fragment>
  );
};

SingleSelectPrompt.propTypes = {
  promptOptions: PropTypes.array,
  updateResponse: PropTypes.func,
  promptResponse: PropTypes.object,
  noCollection: PropTypes.bool,
};
