// File: i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import TRANSLATIONS_EN from "./en/translation.json";
import TRANSLATIONS_DE from "./de/translation.json";


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnEmptyString: false,
    resources: {
      en: {
        translation: TRANSLATIONS_EN
      },
      de: {
        translation: TRANSLATIONS_DE
      }
    }
  });

export { i18n };