import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputGroup, FormGroup, Icon, Intent } from "@blueprintjs/core";
import { useDebounce } from "../../helpers/hooks/useDebounce";

import { useTranslation } from "react-i18next";
import { i18n } from "../../translations/i18n";

export const EmailPrompt = ({ updateResponse, hasError }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validEmail = regEmail.test(inputValue) ? true : false;
  const Helper = ({ visible }) => (
    <span className={visible ? "" : "invisible"}>
      {t('please_provide_valid_email')}
    </span>
  );
  return (
    <div className="flex flex-inherit">
      <FormGroup
        className="mr-2 w-full"
        helperText={<Helper visible={!validEmail} />}
      >
        <InputGroup
          type="email"
          name="email"
          className="bp4-fill"
          id="email_input"
          onChange={(event) => setInputValue(event.target.value)}
          onBlur={(event) => { updateResponse(event.target.value); }}
          intent={hasError ? Intent.DANGER : Intent.NONE}
        />
      </FormGroup>
      <FormGroup>
        <Icon
          icon={validEmail ? "tick" : "cross"}
          intent={validEmail ? Intent.SUCCESS : Intent.DANGER}
          className={`w-5 ${inputValue ? "visible" : "invisible"}`}
        />
      </FormGroup>
    </div>
  );
};

EmailPrompt.propTypes = {
  updateResponse: PropTypes.func,
  hasError: PropTypes.bool,
};
